<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Domain
        </h1>
        <ButtonTemplate :on_click="maybeDelete" btn_style="light" class="my-6" type="button">
            Delete Domain
        </ButtonTemplate>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/domain" :formdata="formData">
                <TextInput type="text" label="Domain" v-model="item.domain" :required="false" ></TextInput>
                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.account" :optiondisplay="accountDisplay" :hideSelected="false"></SearchSelectInput>
                <p>
                    Created On  {{getDate(item.created_at)}}
                </p>
                <p>
                    Last Updated On  {{getDate(item.updated_at)}}
                </p>
            </FormTemplate>
            <Notes :model="{id:item.id,type:'Domain'}"></Notes>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'

export default {
    name: 'Domain',
    mixins: [View],
    data: () => ({
        back: '/domains',
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email'],
            'action': '/search/',
        },
        parameter: 'domain_id',
        action: '/domain/',
    }),
    computed: {
        formData() {
            return {
                'domain':this.item,
            }
        },
    },
    props: [],
    methods: {
        AgencyClick(evt) {
            this.$router.push({path: '/agencies/'+evt.item.id})
        },
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            this.item.account_id = evt.item.id;
            this.item.account = evt.item;
        },
        accountRemoved() {
            this.item.account_id = 0;
            this.item.account = null;
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
