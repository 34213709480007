<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Domains
        </h1>
        <LinkTemplate :to="{path:'/domains/create'}" class="my-6 ml-6">
            Add New Domain
        </LinkTemplate>
    </div>
    <div class="">
        <div class="w-full h-50 flex">
            <div class="flex-1">
                <input type="checkbox" id="id_search" value="id" v-model="searchParams.fields">
                <label for="id_search">ID</label>
            </div>
            <div class="flex-1">
                <input type="checkbox" id="domain_search" value="domain" v-model="searchParams.fields">
                <label for="domain_search">Domain</label>
            </div>
            <div class="flex-1">
                <input type="checkbox" id="account_id_search" value="account_id" v-model="searchParams.fields">
                <label for="account_id_search">Account Id</label>
            </div>
            <div class="flex-1">
                <input type="checkbox" id="strict_search"  v-model="searchParams.strict">
                <label for="strict_search">Strict Search</label>
            </div>
        </div>
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/domains/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/domains/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'DomainList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/domains',
        searchParams: {
            'model': 'Domain',
            'fields': ['id','domain','account_id'],
            'strict' : false,
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'domain',
                label:'Domain',
            },
            {
                id:'account_id',
                label:'Account ID',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
        searchParams: {
            handler() {
                console.log('here')
                this.maybeSearch()
            },
            deep:true,
        },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
