<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Customer
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/domain/create" :formdata="formData">
                <TextInput type="text" label="Domain" v-model="item.domain" :required="false" ></TextInput>
                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.account" :optiondisplay="accountDisplay" :hideSelected="false"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateDomain',
    mixins: [Create],
    data: () => ({
        back: '/domains',
        item: {
            domain: '',
            account: false,
            account_id: 0,
        },
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name'],
            'action': '/search/',
        },
        action: '/domain/',
    }),
    computed: {
        formData() {
            return {
                'domain':this.item,
            }
        },
    },
    props: [],
    methods: {
        AgencyClick(evt) {
            this.$router.push({path: '/agencies/'+evt.item.id})
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            this.item.account_id = evt.item.id;
            this.item.account = evt.item;
        },
        accountRemoved() {
            this.item.account_id = 0;
            this.item.account = null;
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
